import Rollbar from "rollbar";

export const ROLLBAR_FILTER_CONFIG = {
  hydrationErrors: {
    shouldFilter: window.ENV.ROLLBAR_FILTER_HYDRATION_ERRORS || false,
    ignoreList: [
      /Minified React error #418/,
      /Minified React error #423/,
      /Minified React error #425/,
    ],
  },
  jsErrors: {
    shouldFilter: true,
    ignoreList: [
      /The user aborted a request/,
      /Fetch is aborted/,
      /Unexpected token ';'/,
      /Script error/,
      /Cannot read properties of undefined \(reading 'parentNode'\)/,
      /ResizeObserver loop completed with undelivered notifications/,
      /Load failed/,
      /Identifier 'originalPrompt' has already been declared/,
      /Cannot read properties of undefined \(reading '(fireEvent|setScreenSize|setMaxSize|setDefaultPosition|setCurrentPosition|fireChangeEvent|audioVolumeChange|fireReadyEvent)'\)/,
      /ethereum/,
      /Identifier 'listenerName' has already been declared/,
      /evaluating 'a.L'/,
      /zaloJSV2/,
      /supportedSites/,
      /AbortSignal.timeout is not a function/,
      /Maximum call stack size exceeded/,
    ],
  },
};

const ROLLBAR_CONFIG = {
  accessToken: window.ENV.ROLLBAR_POST_CLIENT_ITEM_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: false,
  transmit: window.ENV.ROLLBAR_FE_ENABLED,
  environment: window.ENV.ROLLBAR_ENV,
  payload: {
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: window.ENV.LOTUS_GIT_COMMIT,
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore: (isUncaught: boolean, args: any[], payload: any) => {
    const browserExtensionOrAutomationFile = (frame: any) => {
      const patterns = [/^chrome-extension/, /^safari-extension/, /^moz-extension/, /^pptr/];
      return patterns.some((pattern) => pattern.test(frame.filename));
    };

    return payload.body?.trace?.frames?.some(browserExtensionOrAutomationFile) || false;
  },
  ignoredMessages: ROLLBAR_FILTER_CONFIG.jsErrors.shouldFilter
    ? ROLLBAR_FILTER_CONFIG.jsErrors.ignoreList
    : [],
};

const rollbar = new Rollbar(ROLLBAR_CONFIG);
export default rollbar;
